import { useState } from "react";

export default function buildFormProps(input) {
  const [ value, setValue ] = useState(input);

  return {
    value,
    setValue,
    onChange: e => setValue(e.target.value),
  };
}

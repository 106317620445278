import React from "react";
import history from "../../util/history.js";
import { useAuth0 } from "../container/auth0-wrapper.js";
import LoadingIndicator from "./common/LoadingIndicator.jsx";
import NavBar from "./common/NavBar.jsx";
import { Router } from "react-router-dom";
import Routes from "./Routes";

const App = () => {
  const { isLoading, isAuthenticated, getTokenSilently } = useAuth0();

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading}/>;
  }

  return (
    <Router history={history}>
      <NavBar/>
      <Routes/>
    </Router>
  );
};

export default App;

import { Howl } from "howler";
import config from "./config.json";

const play = (audio, setIsLoading) => {
  return () => {
    if (audio.state() === "unloaded") {
      setIsLoading(true);
      audio.load();
    } else if (audio.state() === "loaded") {
      audio.play();
    }

    audio.once("load", audio.play);
  };
};

const stop = (audio) => {
  return () => audio.stop();
};

export function getAudio(sample, setIsLoading, isPlaying, setIsPlaying) {
  const audio = new Howl({
    src: [ `${config.cloudfrontBaseUri}${sample.key}.webm`, `${config.cloudfrontBaseUri}${sample.key}.mp4` ],
    autoplay: false,
    preload: false,
    onload: () => setIsLoading(false),
    onloaderror: (error) => {
      console.warn(error);
      setIsLoading(false);
    },
    onplay: () => setIsPlaying(true),
    onend: () => setIsPlaying(false),
    onstop: () => setIsPlaying(false),
  });

  return {
    audio,
    play: play(audio, setIsLoading),
    stop: stop(audio),
  };
}

import { deleteRequest, getRequest, postRequest } from "./index.js";

export async function getAllPlaylists() {
  const request = await getRequest("admin/playlists");
  const response = await request.json();

  return response;
}

export async function createPlaylist(name, description) {
  const body = {
    name,
    description,
  };
  const request = await postRequest("admin/playlists", body);

  return await request.json();
}

export async function deletePlaylist(id) {
  const request = await deleteRequest("admin/playlists", { id });

  return await request.json();
}

export async function attachSetToPlaylist(playlistId, setId) {
  const request = await postRequest(`admin/playlists/${playlistId}/sets`, {}, { set_id: setId });

  return await request.json();
}

export async function detachSetFromPlaylist(playlistId, setId) {
  const request = await deleteRequest(`admin/playlists/${playlistId}/sets`, { set_id: setId });

  return await request.json();
}

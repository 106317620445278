import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete.js";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { attachSampleToSet, attachVoidSampleToSet, deleteSet, detachSampleFromSet, detachVoidSampleFromSet, updateSet } from "../../../util/ajax/sets.js";
import filterObjectArray from "../../../util/filterObjectArray.js";
import EditableTextField from "../../common/EditableTextField.jsx";
import EditButtons from "../../common/EditButtons.jsx";
import buildFormProps from "../../state/buildFormProps.js";
import ContentWithIsLoading from "../common/formElements/ContentWithIsLoading.jsx";
import Sample from "../samples/Sample.jsx";
import VoidSample from "../voidSamples/VoidSample.jsx";
import SampleInSet from "./SampleInSet.jsx";
import AddIcon from "@material-ui/icons/Add";
import VoidSampleInSet from "./VoidSampleInSet.jsx";

const Set = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isEditing, setIsEditing ] = useState(false);
  const [ attachSampleIsLoading, setAttachSampleIsLoading ] = useState(false);
  const [ attachVoidSampleIsLoading, setAttachVoidSampleIsLoading ] = useState(false);
  const [ samplesIsOpen, setSamplesIsOpen ] = useState(false);
  const [ voidSamplesIsOpen, setVoidSamplesIsOpen ] = useState(false);
  const nameFormProps = buildFormProps(props.set.name);
  const descriptionFormProps = buildFormProps(props.set.description);

  const onDelete = e => {
    e.preventDefault();

    setIsLoading(true);
    deleteSet(props.set.id)
      .then(props.onDeleteCallback)
      .catch()
      .then(() => {
        setIsLoading(false);
      });
  };

  function addSampleToSet(sampleId) {
    setAttachSampleIsLoading(true);
    attachSampleToSet(props.set.id, sampleId)
      .then(() => {
        props.onDeleteCallback();
      })
      .catch(console.warn)
      .then(() => {
        setAttachSampleIsLoading(false);
      });
  }

  function removeSampleFromSet(sampleId) {
    return detachSampleFromSet(props.set.id, sampleId);
  }

  function addVoidSampleToSet(voidSampleId) {
    setAttachVoidSampleIsLoading(true);
    attachVoidSampleToSet(props.set.id, voidSampleId)
      .then(() => {
        props.onDeleteCallback();
      })
      .catch(console.warn)
      .then(() => {
        setAttachVoidSampleIsLoading(false);
      });
  }

  function removeVoidSampleFromSet(voidSampleId) {
    return detachVoidSampleFromSet(props.set.id, voidSampleId);
  }

  const handleSampleListToggleClick = () => {
    setSamplesIsOpen(!samplesIsOpen);
  };

  const handleVoidSampleListToggleClick = () => {
    setVoidSamplesIsOpen(!voidSamplesIsOpen);
  };

  const filteredSamples = filterObjectArray(props.allSamples, props.set.samples, "id");
  const filteredVoidSamples = filterObjectArray(props.allVoidSamples, props.set.void_samples, "id");

  const onEdit = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };
  const onSave = (e) => {
    e.preventDefault();
    setIsLoading(true);

    updateSet(props.set.id, nameFormProps.value, descriptionFormProps.value)
      .then(() => {
        setIsEditing(false);
      })
      //TODO: Wire in an error manager.
      .catch(console.warn)
      .then(() => {
        setIsLoading(false);
      });
  };

  const onCancel = (e) => {
    e.preventDefault();
    nameFormProps.setValue(props.set.name);
    descriptionFormProps.setValue(props.set.description);
    setIsEditing(false);
  };

  return <Card>
    <form onSubmit={onSave}>
      <CardContent>
        <Typography variant={"h4"}>
          <EditableTextField
            isEditing={isEditing}
            isLoading={isLoading}
            formProps={nameFormProps}
            autoFocus
          />
        </Typography>
        <p><EditableTextField
          isEditing={isEditing}
          isLoading={isLoading}
          formProps={descriptionFormProps}
        /></p>
        <List subheader={<ListSubheader>Samples</ListSubheader>}>
          <ListItem button onClick={handleSampleListToggleClick}>
            <ListItemText primary="Attach a Sample"/>
            {samplesIsOpen ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>
          <Collapse in={samplesIsOpen} timeout="auto" unmountOnExit>
            <List>
              {!!filteredSamples.length && filteredSamples.map((sample, i) => (
                <Sample
                  key={i}
                  sample={sample}
                  onDeleteCallback={onDelete}
                  withDelete={false}
                  actionButton={(
                    <IconButton onClick={() => addSampleToSet(sample.id)} color={"primary"} disabled={isLoading}>
                      <ContentWithIsLoading isLoading={attachSampleIsLoading} content={<AddIcon/>}/>
                    </IconButton>
                  )}
                />
              ))}
            </List>
          </Collapse>
          {
            !!props.set.samples.length && props.set.samples.map((sample, i) => (
                <SampleInSet
                  key={i}
                  sample={sample}
                  removeSampleFromSet={removeSampleFromSet}
                  onRemoveCallback={props.onDeleteCallback}
                />
              )
            )
          }
        </List>
        <List subheader={<ListSubheader>Void Samples</ListSubheader>}>
          <ListItem button onClick={handleVoidSampleListToggleClick}>
            <ListItemText primary="Attach a Void Sample"/>
            {voidSamplesIsOpen ? <ExpandLess/> : <ExpandMore/>}
          </ListItem>
          <Collapse in={voidSamplesIsOpen} timeout="auto" unmountOnExit>
            <List>
              {!!filteredVoidSamples.length && filteredVoidSamples.map((voidSample, i) => (
                <VoidSample
                  key={i}
                  voidSample={voidSample}
                  onDeleteCallback={onDelete}
                  withDelete={false}
                  actionButton={(
                    <IconButton onClick={() => addVoidSampleToSet(voidSample.id)} color={"primary"} disabled={isLoading}>
                      <ContentWithIsLoading isLoading={attachVoidSampleIsLoading} content={<AddIcon/>}/>
                    </IconButton>
                  )}
                />
              ))}
            </List>
          </Collapse>
          {
            !!props.set.void_samples.length && props.set.void_samples.map((voidSample, i) => (
                <VoidSampleInSet
                  key={i}
                  voidSample={voidSample}
                  removeVoidSampleFromSet={removeVoidSampleFromSet}
                  onRemoveCallback={props.onDeleteCallback}
                />
              )
            )
          }
        </List>
      </CardContent>
      <CardActions>
        <EditButtons
          isLoading={isLoading}
          isEditing={isEditing}
          onEdit={onEdit}
          onSave={onSave}
          onCancel={onCancel}
        />
        <IconButton variant="contained" color="secondary" onClick={onDelete}>
          <ContentWithIsLoading isLoading={isLoading} content={<DeleteIcon/>}/>
        </IconButton>
      </CardActions>
    </form>
  </Card>;
};

Set.propTypes = {
  set: PropTypes.object.isRequired,
  onDeleteCallback: PropTypes.func,
  allSamples: PropTypes.array,
  allVoidSamples: PropTypes.array,
};

export default Set;

import { AUTH_TOKEN_KEY } from "../../config/localStorage.js";
import { buildRequestUrl } from "./utils.js";

const BASE_CONFIG = {};
const BASE_HEADERS = {
  Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
};


export async function getRequest(path, params = {}, requestConfig = {}) {
  const url = buildRequestUrl(path, params);
  const config = {
    headers: { ...BASE_HEADERS },
    ...BASE_CONFIG,
    ...requestConfig,
    method: "GET",
  };

  return fetch(url, config);
}

export async function postRequest(path, body, params = {}, requestConfig = {}) {
  const url = buildRequestUrl(path, params);
  const isFormData = body instanceof FormData;
  const contentTypeHeaders = isFormData ? {} : {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };
  const config = {
    headers: { ...BASE_HEADERS, ...contentTypeHeaders },
    ...BASE_CONFIG,
    ...requestConfig,
    method: "POST",
    body: isFormData ? body : JSON.stringify(body),
  };

  return fetch(url, config);
}

export async function patchRequest(path, body = {}, params = {}, requestConfig = {}) {
  const url = buildRequestUrl(path, params);
  const config = {
    headers: {
      ...BASE_HEADERS,
      "Content-Type": "application/json",
    },
    ...BASE_CONFIG,
    ...requestConfig,
    method: "PATCH",
    body: JSON.stringify(body)
  };

  return fetch(url, config);
}

export async function deleteRequest(path, params = {}, requestConfig = {}) {
  const url = buildRequestUrl(path, params);
  const config = {
    headers: { ...BASE_HEADERS },
    ...BASE_CONFIG,
    ...requestConfig,
    method: "DELETE",
  };

  return fetch(url, config);
}

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import React, { useState, useEffect } from "react";
import { fetchSamples } from "../../../util/fetchToState.js";
import AddSampleForm from "./AddSampleForm.jsx";
import LoadingIndicator from "../common/LoadingIndicator.jsx";
import Sample from "./Sample.jsx";

const Samples = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ samples, setSamples ] = useState([]);

  const onDelete = async (id) => {
    removeSampleById(id);
  };

  const removeSampleById = (id) => setSamples(samples.filter(sample => sample.id !== id));

  useEffect(() => {
    fetchSamples(setSamples, setIsLoading);
  }, []);

  return <div>
    <h1>Samples</h1>
    <LoadingIndicator isLoading={isLoading}/>
    <List>
      <ListItem>
        <AddSampleForm reloadSamplesCallback={() => fetchSamples(setSamples, setIsLoading)}/>
      </ListItem>
      {
        !!samples.length && samples.map((sample, i) => <Sample key={i} sample={sample} onDeleteCallback={onDelete}/>)
      }
    </List>
  </div>;
};

export default Samples;

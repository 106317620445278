import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../container/PrivateRoute.jsx";
import Home from "./Home.jsx";
import Playlists from "./playlists/Playlists.jsx";
import Samples from "./samples/Samples.jsx";
import Sets from "./sets/Sets.jsx";
import VoidSamples from "./voidSamples/VoidSamples.jsx";

export default function Routes() {
  return (
    <>
      <Route path={"/"} component={Home} exact={true}/>
      <PrivateRoute path={"/playlists"} component={Playlists}/>
      <PrivateRoute path={"/sets"} component={Sets}/>
      <PrivateRoute path={"/samples"} component={Samples}/>
      <PrivateRoute path={"/void_samples"} component={VoidSamples}/>
    </>
  );
}

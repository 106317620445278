import { deleteRequest, getRequest, patchRequest, postRequest } from "./index.js";

export async function getAllSets() {
  const request = await getRequest("admin/sets");
  const responseJson = await request.json();

  responseJson.forEach(set => set.void_samples.sort((a, b) => a.duration - b.duration));

  return responseJson;
}

export async function createSet(name, description) {
  const body = {
    name,
    description,
  };
  const request = await postRequest("admin/sets", body);

  return await request.json();
}

export async function updateSet(id, name, description) {
  const body = {
    name,
    description,
  };
  const request = await patchRequest("admin/sets", body, { id });

  return await request.json();
}

export async function deleteSet(id) {
  const request = await deleteRequest("admin/sets", { id });

  return await request.json();
}

export async function attachSampleToSet(setId, sampleId) {
  const request = await postRequest(`admin/sets/${setId}/samples`, {}, { sample_id: sampleId });

  return await request.json();
}

export async function detachSampleFromSet(setId, sampleId) {
  const request = await deleteRequest(`admin/sets/${setId}/samples`, { sample_id: sampleId });

  return await request.json();
}

export async function attachVoidSampleToSet(setId, voidSampleId) {
  const request = await postRequest(`admin/sets/${setId}/void_samples`, {}, { void_sample_id: voidSampleId });

  return await request.json();
}

export async function detachVoidSampleFromSet(setId, voidSampleId) {
  const request = await deleteRequest(`admin/sets/${setId}/void_samples`, { void_sample_id: voidSampleId });

  return await request.json();
}

import { getAllPlaylists } from "./ajax/playlists.js";
import { getAllSamples } from "./ajax/samples.js";
import { getAllSets } from "./ajax/sets.js";
import { getVoidSamples } from "./ajax/voidSamples.js";

export const fetchPlaylists = async (setPlaylists, setIsLoading) => {
  setIsLoading(true);
  const playlists = await getAllPlaylists();

  setPlaylists(playlists);
  setIsLoading(false);
};

export const fetchSets = async (setSets, setIsLoading) => {
  setIsLoading(true);
  const sets = await getAllSets();

  setSets(sets);
  setIsLoading(false);
};

export const fetchSamples = async (setSamples, setIsLoading) => {
  setIsLoading(true);
  const samples = await getAllSamples();

  setSamples(samples);
  setIsLoading(false);
};

export const fetchVoidSamples = async (setVoidSamples, setIsLoading) => {
  setIsLoading(true);
  const voidSamples = await getVoidSamples();

  setVoidSamples(voidSamples);
  setIsLoading(false);
};

import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ContentWithIsLoading from "../common/formElements/ContentWithIsLoading.jsx";
import ClearIcon from "@material-ui/icons/Clear.js";

const VoidSampleInSet = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ audioIsLoading, setAudioIsLoading ] = useState(false);

  function onRemove(e) {
    e.preventDefault();
    setIsLoading(true);

    props.removeVoidSampleFromSet(props.voidSample.id)
         .then(() => {
           props.onRemoveCallback();
         })
         .catch(console.warn)
         .then(() => {
           setIsLoading(false);
         });
  }

  return <ListItem>
    <ListItemText primary={props.voidSample.duration}>{props.voidSample.duration}</ListItemText>
    <ListItemSecondaryAction>
      <IconButton onClick={onRemove} color={"secondary"} disabled={isLoading}>
        <ContentWithIsLoading isLoading={isLoading} content={<ClearIcon/>}/>
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>;
};

export default VoidSampleInSet;

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { createSet } from "../../../util/ajax/sets.js";
import ContentWithIsLoading from "../common/formElements/ContentWithIsLoading.jsx";

const AddSetForm = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ name, setName ] = useState("");
  const [ description, setDescription ] = useState("");

  function addSet(e) {
    setIsLoading(true);
    e.preventDefault();
    createSet(name, description)
      .then(() => {
        setName("");
        setDescription("");
        props.reloadSetsCallback();
      })
      .catch(e => {})
      .then((() => {
        setIsLoading(false);
      }));
  }

  return <div>
    <h2>Add a Set</h2>
    <form onSubmit={addSet}>
      <TextField label="Name" value={name} onChange={e => setName(e.target.value)} disabled={isLoading}/>
      <TextField label="Description" value={description} onChange={e => setDescription(e.target.value)} disabled={isLoading}/>
      <Button variant="contained" color="primary" type={"submit"}>
        <ContentWithIsLoading content={"Add"} isLoading={isLoading}/>
      </Button>
    </form>
  </div>;
};

export default AddSetForm;

import { getRequest, postRequest, deleteRequest, patchRequest } from "./index.js";

export async function getAllSamples() {
  const request = await getRequest("admin/samples");

  return await request.json();
}

export async function createSample(file, name, description) {
  const body = new FormData();

  body.append("sample", file);
  body.append("name", name);
  body.append("description", description);

  const request = await postRequest("admin/samples", body);

  return request;
}

export async function deleteSample(id) {
  const request = await deleteRequest("admin/samples", { id });

  return request;
}

export async function updateSample(id, name, description) {
  const request = await patchRequest("/admin/samples", { name, description }, { id });

  return request;
}

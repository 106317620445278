import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { createPlaylist } from "../../../util/ajax/playlists.js";
import ContentWithIsLoading from "../common/formElements/ContentWithIsLoading.jsx";

const AddPlaylistForm = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ name, setName ] = useState("");
  const [ description, setDescription ] = useState("");

  function addPlaylist(e) {
    setIsLoading(true);
    e.preventDefault();
    createPlaylist(name, description)
      .then(() => {
        setName("");
        setDescription("");
        props.reloadPlaylistsCallback();
      })
      .catch(console.warn)
      .then(() => {
        setIsLoading(false);
      });
  }

  return <div>
    <form onSubmit={addPlaylist}>
      <TextField label="Name" value={name} onChange={e => setName(e.target.value)} disabled={isLoading}/>
      <TextField label="Description" value={description} onChange={e => setDescription(e.target.value)} disabled={isLoading}/>
      <Button variant="contained" color="primary" type={"submit"}>
        <ContentWithIsLoading content={"Add"} isLoading={isLoading}/>
      </Button>
    </form>
  </div>;
};

export default AddPlaylistForm;

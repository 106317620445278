import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { createSample } from "../../../util/ajax/samples.js";
import ContentWithIsLoading from "../common/formElements/ContentWithIsLoading.jsx";
import FileInput from "../common/formElements/FileInput.jsx";

const AddSampleForm = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ name, setName ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ file, getFile ] = useState(null);

  function addSample(e) {
    e.preventDefault();
    setIsLoading(true);
    createSample(file, name, description)
      .then(() => {
        setName("");
        setDescription("");
        props.reloadSamplesCallback();
      })
      .catch()
      .then(() => {
        setIsLoading(false);
      });
  }

  return <div>
    <h2>Add a sample</h2>
    <form onSubmit={addSample}>
      <FileInput label={"FLAC File"} onChange={getFile} disabled={isLoading}/>
      <TextField label="Name" value={name} onChange={e => setName(e.target.value)} disabled={isLoading}/>
      <TextField label="Description" value={description} onChange={e => setDescription(e.target.value)} disabled={isLoading}/>
      <Button variant="contained" color="primary" type={"submit"}>
        <ContentWithIsLoading content={"Add Sample"} isLoading={isLoading}/>
      </Button>
    </form>
  </div>;
};

AddSampleForm.defaultProps = {
  reloadSamplesCallback: () => {},
};

AddSampleForm.propTypes = {
  reloadSamplesCallback: PropTypes.func,
};

export default AddSampleForm;

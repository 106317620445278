import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete.js";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { deleteVoidSample } from "../../../util/ajax/voidSamples.js";
import ContentWithIsLoading from "../common/formElements/ContentWithIsLoading.jsx";

const VoidSample = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);

  function onDelete() {
    setIsLoading(true);
    deleteVoidSample(props.voidSample.id)
      .then(() => {
        props.onDeleteCallback();
      })
      .catch(console.warn)
      .then(() => {
        setIsLoading(false);
      });
  }

  return <ListItem>
    <ListItemText primary={`${props.voidSample.duration} seconds`}/>
    <ListItemSecondaryAction>
      {props.actionButton}
      {props.withDelete &&
      <IconButton onClick={onDelete} color={"secondary"} disabled={isLoading}>
        <ContentWithIsLoading isLoading={isLoading} content={<DeleteIcon/>}/>
      </IconButton>
      }
    </ListItemSecondaryAction>
  </ListItem>;
};

VoidSample.defaultProps = {
  onDeleteCallback: () => {},
  withDelete: true,
};

VoidSample.propTypes = {
  voidSample: PropTypes.object,
  onDeleteCallback: PropTypes.func,
  withDelete: PropTypes.bool,
  actionButton: PropTypes.node,
};

export default VoidSample;

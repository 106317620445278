import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { createVoidSample } from "../../../util/ajax/voidSamples.js";
import ContentWithIsLoading from "../common/formElements/ContentWithIsLoading.jsx";

const AddVoidSampleForm = (props) => {
  const [ duration, setDuration ] = useState("");
  const [ isLoading, setIsLoading ] = useState(false);

  function addVoidSample(e) {
    e.preventDefault();
    setIsLoading(true);

    createVoidSample(parseInt(duration))
      .then(() => {
        setDuration("");
        props.onAddCallback();
      })
      .catch(console.warn)
      .then(() => {
        setIsLoading(false);
      });
  }

  return <div>
    <h2>Add a Void Sample</h2>
    <form onSubmit={addVoidSample}>
      <TextField label={"Duration"} value={duration} onChange={e => setDuration(e.target.value)} disabled={isLoading} type={"number"}/>
      <Button variant="contained" color="primary" type={"submit"}>
        <ContentWithIsLoading content={"Add"} isLoading={isLoading}/>
      </Button>
    </form>
  </div>;
};

export default AddVoidSampleForm;

import React, { useEffect, useState } from "react";
import { fetchSamples, fetchSets, fetchVoidSamples } from "../../../util/fetchToState.js";
import LoadingIndicator from "../common/LoadingIndicator.jsx";
import AddSetForm from "./AddSetForm.jsx";
import Set from "./Set.jsx";

const Sets = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ sets, setSets ] = useState([]);
  const [ samples, setSamples ] = useState([]);
  const [ voidSamples, setVoidSamples ] = useState([]);
  const loadSets = () => {
    return fetchSets(setSets, setIsLoading);
  };

  useEffect(() => {
    Promise.all([
             loadSets(),
             fetchSamples(setSamples, setIsLoading),
             fetchVoidSamples(setVoidSamples, setIsLoading),
           ])
           .catch(console.warn);
  }, []);

  return <div>
    <h1>Sets</h1>
    <LoadingIndicator isLoading={isLoading}/>
    <AddSetForm reloadSetsCallback={loadSets}/>
    {
      !!sets.length && sets.map((set, i) => (
          <Set
            key={i}
            set={set}
            onDeleteCallback={loadSets}
            allSamples={samples}
            allVoidSamples={voidSamples}
          />
        )
      )
    }
  </div>;
};

export default Sets;

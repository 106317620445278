import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ClearIcon from "@material-ui/icons/Clear.js";
import React, { useState } from "react";
import ContentWithIsLoading from "../common/formElements/ContentWithIsLoading.jsx";

const SetInPlaylist = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);

  function detachSet() {
    setIsLoading(true);
    props.onDetach(props.set.id)
         .then(props.onDetachCallback)
         .catch(console.warn)
         .then(() => setIsLoading(false));
  }

  return <ListItem>
    <ListItemText primary={props.set.name} secondary={props.set.description}>{props.set.name}</ListItemText>
    <ListItemSecondaryAction>
      {props.withRemove &&
      <IconButton onClick={detachSet} color={"secondary"} disabled={isLoading}>
        <ContentWithIsLoading isLoading={isLoading} content={<ClearIcon/>}/>
      </IconButton>
      }
      {
        props.actionButton && props.actionButton
      }
    </ListItemSecondaryAction>
  </ListItem>;
};

SetInPlaylist.defaultProps = {
  withRemove: true,
};

export default SetInPlaylist;

import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import { Link } from "react-router-dom";
import { AUTH_TOKEN_KEY } from "../../../config/localStorage.js";
import { useAuth0 } from "../../container/auth0-wrapper.js";

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <header>
      <CssBaseline/>
      {!isAuthenticated && <button onClick={() => loginWithRedirect({})}>Log in</button>}
      {isAuthenticated && <>
        <ul>
          <li>
            <Link to={"/"}>Home</Link>
          </li>
          <li>
            <Link to={"/playlists"}>Playlists</Link>
          </li>
          <li>
            <Link to={"/sets"}>Sets</Link>
          </li>
          <li>
            <Link to={"/samples"}>Samples</Link>
          </li>
          <li>
            <Link to={"/void_samples"}>Void Samples</Link>
          </li>
        </ul>
        <button onClick={() => {
          localStorage.removeItem(AUTH_TOKEN_KEY);
          logout();
        }}>Log out</button>
      </>}
    </header>
  );
};

export default NavBar;

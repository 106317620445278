import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import PropTypes from "prop-types";

const LoadingIndicator = ({ isLoading }) => {
  if (isLoading) {
    return <CircularProgress/>;
  }

  return null;
};

LoadingIndicator.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default LoadingIndicator;

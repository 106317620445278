import React from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";

const EditableTextField = (props) => props.isEditing ? <input
  value={props.formProps.value}
  onChange={props.formProps.onChange}
  disabled={props.isLoading}
  {...omit(props, [ "isEditing", "isLoading", "formProps" ])}
/> : props.formProps.value;

EditableTextField.propTypes = {
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  formProps: PropTypes.object,
};

export default EditableTextField;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel.js";
import EditIcon from "@material-ui/icons/Edit.js";
import SaveIcon from "@material-ui/icons/Save.js";

const EditButtons = ({ isEditing, isLoading, onSave, onCancel, onEdit }) => isEditing ?
  <Fragment>
    <IconButton onClick={onSave} disabled={isLoading} type={"submit"}>
      <SaveIcon/>
    </IconButton>
    <IconButton onClick={onCancel} disabled={isLoading}>
      <CancelIcon/>
    </IconButton>
  </Fragment> :
  <IconButton onClick={onEdit} disabled={isLoading}>
    <EditIcon/>
  </IconButton>;

EditButtons.propTypes = {
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
};

export default EditButtons;

import config from "./config.json";

export default function (sets) {
  return sets.map(curr => {
    const samples = curr.samples.map(sample => ({
        type: "sample",
        src: [
          `${config.cloudfrontBaseUri}${sample.key}.webm`,
          `${config.cloudfrontBaseUri}${sample.key}.mp4`,
        ],
        weight: 1,
        minVolume: 1,
        maxVolume: 1,
        playbackRate: 1,
        fadeTimeout: 1,
      })
    );
    const voidSamples = curr.void_samples.map(voidSample => ({
        type: "void",
        duration: voidSample.duration,
        weight: 1,
      })
    );
    const set = {
      name: curr.name,
      samples: [ ...samples, ...voidSamples ],
    };

    return set;
  }, []);
}

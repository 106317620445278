import { ListItem } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete.js";
import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { deleteSample, updateSample } from "../../../util/ajax/samples.js";
import { getAudio } from "../../../util/playSample.js";
import EditableTextField from "../../common/EditableTextField.jsx";
import EditButtons from "../../common/EditButtons.jsx";
import buildFormProps from "../../state/buildFormProps.js";
import ContentWithIsLoading from "../common/formElements/ContentWithIsLoading.jsx";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";

const Sample = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ audioIsLoading, setAudioIsLoading ] = useState(false);
  const [ isPlaying, setIsPlaying ] = useState(false);
  const [ isEditing, setIsEditing ] = useState(false);
  const nameFormProps = buildFormProps(props.sample.name);
  const descriptionFormProps = buildFormProps(props.sample.description);
  const [ audio ] = useState(getAudio(props.sample, setAudioIsLoading, isPlaying, setIsPlaying));

  const onDelete = async (e) => {
    e.preventDefault();
    audio.stop();
    setIsLoading(true);
    await deleteSample(props.sample.id);
    setIsLoading(false);
    props.onDeleteCallback(props.sample.id);
  };

  const onPlay = (e) => {
    e.preventDefault();
    audio.play();
  };

  const onStop = (e) => {
    e.preventDefault();
    audio.stop();
  };

  const onPlayClick = isPlaying ? onStop : onPlay;
  const playButtonContent = isPlaying ? <StopIcon/> : <PlayArrowIcon/>;

  const onEdit = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const onSave = (e) => {
    e.preventDefault();
    setIsLoading(true);

    updateSample(props.sample.id, nameFormProps.value, descriptionFormProps.value)
      .then(() => {
        setIsEditing(false);
      })
      //TODO: Wire in an error manager.
      .catch(console.warn)
      .then(() => {
        setIsLoading(false);
      });
  };

  const onCancel = (e) => {
    e.preventDefault();
    nameFormProps.setValue(props.sample.name);
    descriptionFormProps.setValue(props.sample.description);
    setIsEditing(false);
  };

  return <ListItem>
    <form onSubmit={onSave}>
      <ListItemText
        primary={<EditableTextField isEditing={isEditing} isLoading={isLoading} formProps={nameFormProps} autoFocus/>}
        secondary={<EditableTextField isEditing={isEditing} isLoading={isLoading} formProps={descriptionFormProps}/>}
      />
      <ListItemSecondaryAction>
        <EditButtons
          isEditing={isEditing}
          isLoading={isLoading}
          onEdit={onEdit}
          onSave={onSave}
          onCancel={onCancel}
        />
        <IconButton onClick={onPlayClick} color={"primary"} disabled={audioIsLoading || isLoading}>
          <ContentWithIsLoading isLoading={audioIsLoading} content={playButtonContent}/>
        </IconButton>
        {props.actionButton}
        {props.withDelete &&
        <IconButton onClick={onDelete} color={"secondary"} disabled={isLoading}>
          <ContentWithIsLoading isLoading={isLoading} content={<DeleteIcon/>}/>
        </IconButton>
        }
      </ListItemSecondaryAction>
    </form>
  </ListItem>;
};

Sample.defaultProps = {
  onDeleteCallback: () => {},
  withDelete: true,
};

Sample.propTypes = {
  sample: PropTypes.object.isRequired,
  onDeleteCallback: PropTypes.func,
  withDelete: PropTypes.bool,
  actionButton: PropTypes.node,
};

export default Sample;

import StopIcon from "@material-ui/icons/Stop.js";
import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { getAudio } from "../../../util/playSample.js";
import ContentWithIsLoading from "../common/formElements/ContentWithIsLoading.jsx";
import ClearIcon from "@material-ui/icons/Clear.js";
import PlayArrowIcon from "@material-ui/icons/PlayArrow.js";

const SampleInSet = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ audioIsLoading, setAudioIsLoading ] = useState(false);
  const [ isPlaying, setIsPlaying ] = useState(false);
  const [ audio ] = useState(getAudio(props.sample, setAudioIsLoading, isPlaying, setIsPlaying));

  function onRemove(e) {
    e.preventDefault();
    setIsLoading(true);
    audio.stop();

    props.removeSampleFromSet(props.sample.id)
         .then(() => {
           props.onRemoveCallback();
         })
         .catch(console.warn)
         .then(() => {
           setIsLoading(false);
         });
  }

  const onPlay = (e) => {
    e.preventDefault();
    audio.play();
  };

  const onStop = (e) => {
    e.preventDefault();
    audio.stop();
  };

  const onClick = isPlaying ? onStop : onPlay;
  const content = isPlaying ? <StopIcon/> : <PlayArrowIcon/>;

  return <ListItem>
    <ListItemText primary={props.sample.name} secondary={props.sample.description}>{props.sample.name}</ListItemText>
    <ListItemSecondaryAction>
      <IconButton onClick={onClick} color={"primary"} disabled={audioIsLoading}>
        <ContentWithIsLoading isLoading={audioIsLoading} content={content}/>
      </IconButton>
      <IconButton onClick={onRemove} color={"secondary"} disabled={isLoading}>
        <ContentWithIsLoading isLoading={isLoading} content={<ClearIcon/>}/>
      </IconButton>
    </ListItemSecondaryAction>
  </ListItem>;
};

export default SampleInSet;

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import React, { useEffect, useState } from "react";
import { getVoidSamples } from "../../../util/ajax/voidSamples.js";
import AddVoidSampleForm from "./AddVoidSampleForm.jsx";
import VoidSample from "./VoidSample.jsx";

const VoidSamples = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ voidSamples, setVoidSamples ] = useState([]);

  async function loadVoidSamples() {
    setIsLoading(true);
    getVoidSamples()
      .then(setVoidSamples)
      .catch(console.warn)
      .then(() => {
        setIsLoading(false);
      });
  }

  function onDelete() {
    loadVoidSamples();
  }

  useEffect(() => {
    loadVoidSamples();
  }, []);

  return <div>
    <h1>Void Samples</h1>
    <List>
      <ListItem>
        <AddVoidSampleForm onAddCallback={loadVoidSamples}/>
      </ListItem>
      {
        !!voidSamples.length && voidSamples.map((voidSample, i) => <VoidSample key={i} voidSample={voidSample} onDeleteCallback={onDelete}/>)
      }
    </List>

  </div>;
};

export default VoidSamples;

import React, { useState, useEffect } from "react";
import { getAllPlaylists } from "../../../util/ajax/playlists.js";
import { fetchPlaylists, fetchSets } from "../../../util/fetchToState.js";
import LoadingIndicator from "../common/LoadingIndicator.jsx";
import AddPlaylistForm from "./AddPlaylistForm.jsx";
import Playlist from "./Playlist.jsx";

const Playlists = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ playlists, setPlaylists ] = useState([]);
  const [ sets, setSets ] = useState([]);

  function loadPlaylists() {
    return fetchPlaylists(setPlaylists, setIsLoading);
  }

  useEffect(() => {
    Promise.all([
             loadPlaylists(),
             fetchSets(setSets, setIsLoading),
           ])
           .catch(console.warn);
  }, []);

  return <div>
    <h1>Playlists</h1>
    <LoadingIndicator isLoading={isLoading}/>
    <AddPlaylistForm reloadPlaylistsCallback={loadPlaylists}/>
    {playlists.map((playlist, i) => (
        <Playlist
          key={i}
          playlist={playlist}
          onDeleteCallback={loadPlaylists}
          allSets={sets}/>
      )
    )}
  </div>;
};

export default Playlists;

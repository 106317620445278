import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import PropTypes from "prop-types";

const ContentWithIsLoading = (props) => {
  if (props.isLoading) {
    return <CircularProgress/>;
  }

  return props.content;
};

ContentWithIsLoading.defaultProps = {
  isLoading: false,
};

ContentWithIsLoading.propTypes = {
  content: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default ContentWithIsLoading;

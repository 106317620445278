import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add.js";
import DeleteIcon from "@material-ui/icons/Delete.js";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import StopIcon from "@material-ui/icons/Stop";
import React, { useState } from "react";
import { loadTracks } from "tyche-engine/dist/loadTracks.js";
import Track from "tyche-engine/dist/Track.js";
import TycheEngine from "tyche-engine/dist/TycheEngine.js";
import { attachSetToPlaylist, deletePlaylist, detachSetFromPlaylist } from "../../../util/ajax/playlists.js";
import filterObjectArray from "../../../util/filterObjectArray.js";
import getPlayablePlaylist from "../../../util/getPlayablePlaylist.js";
import ContentWithIsLoading from "../common/formElements/ContentWithIsLoading.jsx";
import SetInPlaylist from "./SetInPlaylist.jsx";

const Playlist = (props) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ attachSetIsLoading, setAttachSetIsLoading ] = useState(false);
  const [ setIsOpen, setSetIsOpen ] = useState(false);
  const [ isPlaying, setIsPlaying ] = useState(false);
  const [ engine ] = useState(new TycheEngine(Track));
  const filteredSets = filterObjectArray(props.allSets, props.playlist.sets, "id");
  const playablePlaylist = getPlayablePlaylist(props.playlist.sets);

  function onDelete(e) {
    e.preventDefault();

    setIsLoading(true);
    deletePlaylist(props.playlist.id)
      .then(props.onDeleteCallback)
      .catch(console.warn)
      .then(() => {
        setIsLoading(false);
      });
  }

  function handleSetListToggleClick() {
    setSetIsOpen(!setIsOpen);
  }

  function addSetToPlaylist(setId) {
    setIsLoading(true);
    attachSetToPlaylist(props.playlist.id, setId)
      .then(props.onDeleteCallback)
      .catch(console.warn)
      .then(() => {
        setIsLoading(false);
      });
  }

  function removeSetFromPlaylist(setId) {
    return detachSetFromPlaylist(props.playlist.id, setId);
  }

  function playPlaylist() {
    //TODO: Start loading indicator
    loadTracks(playablePlaylist)
      .then((playlist) => {
        engine.startPlaylist(playlist);
        setIsPlaying(true);
      })
      .catch(console.warn)
      .then(() => {
        //  TODO: Stop loading
      });
  }

  function stopPlaylist() {
    engine.stop();
    setIsPlaying(false);
  }

  return <Card>
    <CardContent>
      <Typography variant={"h4"}>{props.playlist.name}</Typography>
      <p>{props.playlist.description}</p>
      <List>
        <ListItem button onClick={handleSetListToggleClick}>
          <ListItemText primary="Attach a Set"/>
          {setIsOpen ? <ExpandLess/> : <ExpandMore/>}
        </ListItem>
        <Collapse in={setIsOpen} timeout="auto" unmountOnExit>
          <List>
            {!!filteredSets.length && filteredSets.map((set, i) => (
              <SetInPlaylist
                key={i}
                set={set}
                withRemove={false}
                actionButton={(
                  <IconButton onClick={() => addSetToPlaylist(set.id)} color={"primary"} disabled={isLoading}>
                    <ContentWithIsLoading isLoading={attachSetIsLoading} content={<AddIcon/>}/>
                  </IconButton>
                )}
              />
            ))}
          </List>
        </Collapse>
        {
          !!props.playlist.sets.length && props.playlist.sets.map((set, i) => (
            <SetInPlaylist
              key={i}
              set={set}
              onDetach={removeSetFromPlaylist}
              onDetachCallback={props.onDeleteCallback}
            />
          ))
        }
      </List>
    </CardContent>
    <CardActions>
      <IconButton variant="contained" color="secondary" onClick={onDelete}>
        <ContentWithIsLoading isLoading={isLoading} content={<DeleteIcon/>}/>
      </IconButton>
      {isPlaying ?
        <IconButton variant={"contained"} color={"primary"} onClick={stopPlaylist}>
          <ContentWithIsLoading isLoading={false} content={<StopIcon/>}/>
        </IconButton>
        : <IconButton variant={"contained"} color={"primary"} onClick={playPlaylist}>
          <ContentWithIsLoading isLoading={false} content={<PlayCircleOutlineIcon/>}/>
        </IconButton>
      }
    </CardActions>
  </Card>;
};

export default Playlist;

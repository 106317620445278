import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import PropTypes from "prop-types";

const FileInput = (props = {}) => {
  const propsFilter = [ "label", "type" ];
  const [ fileName, setFileName ] = useState("");
  const inputProps = Object.keys(props)
                           .filter(prop => !propsFilter.includes(prop))
                           .reduce((acc, curr) => ({ ...acc, [ curr ]: props[ curr ] }), {});
  const fileInput = React.createRef();

  const onChangeCallback = e => {
    e.preventDefault();

    if (fileInput.current && fileInput.current.files.length) {
      setFileName(fileInput.current.files[ 0 ].name);
    } else {
      setFileName("");
    }

    props.onChange(fileInput.current.files[ 0 ]);
  };


  return <div>
    <Button
      variant="contained"
      component="label"
    >
      {props.label}
      <input {...inputProps} type={"file"} ref={fileInput} onChange={onChangeCallback} style={{ display: "none" }}/>
    </Button>
    {fileName}
  </div>;
};

FileInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onChange: PropTypes.func,
  classname: PropTypes.string,
  disabled: PropTypes.bool,
};

FileInput.defaultProps = {
  type: "text",
  onChange: () => {},
};

export default FileInput;

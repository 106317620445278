import { deleteRequest, getRequest, postRequest } from "./index.js";

export async function createVoidSample(duration) {
  const request = await postRequest("admin/void_samples", { duration });

  return request.json();
}

export async function getVoidSamples() {
  const request = await getRequest("admin/void_samples");
  const json = await request.json();

  json.sort((a, b) => a.duration - b.duration);

  return json;
}

export async function deleteVoidSample(id) {
  const request = await deleteRequest(`admin/void_samples`, { id });

  return request.json();
}

function urlEncodeParams(params) {
  return Object.keys(params).map(param => `${param}=${params[ param ]}`);
}

export function buildRequestUrl(path, params) {
  const baseUrl = getBaseUrl(window.location.hostname);
  const urlEncodedParams = urlEncodeParams(params).join("&");
  const url = encodeURI(`${baseUrl}${path}?${urlEncodedParams}`);

  return url;
}

export function getBaseUrl(hostname) {
  const baseUrls = {
    "localhost": "http://localhost:3001/",
    "omtone-admin.netlify.com": "https://api.omtone.life/",
    "admin.omtone.life": "https://api.omtone.life/",
  };

  return baseUrls[ hostname ];
}
